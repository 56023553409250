import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { Facebook, Twitter, Linkedin, Github, Send } from 'lucide-react';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the email to your backend
    // For this example, we'll just simulate a successful subscription
    if (email) {
      setMessage({ type: 'success', text: 'Thank you for subscribing!' });
      setEmail('');
      // Reset message after 3 seconds
      setTimeout(() => setMessage(null), 3000);
    } else {
      setMessage({ type: 'danger', text: 'Please enter a valid email.' });
    }
  };

  return (
    <footer className="bg-dark text-light py-5">
      <Container>
        <Row className="gy-4">
          <Col lg={4}>
            <h5 className="mb-3">About Qfractalia</h5>
            <p className="mb-4">Empowering businesses with cutting-edge technology solutions. We drive innovation and growth through digital transformation.</p>
            <div className="social-icons">
              <a href="#" className="me-3" aria-label="Facebook"><Facebook size={24} /></a>
              <a href="#" className="me-3" aria-label="Twitter"><Twitter size={24} /></a>
              <a href="#" className="me-3" aria-label="LinkedIn"><Linkedin size={24} /></a>
              <a href="#" aria-label="GitHub"><Github size={24} /></a>
            </div>
          </Col>
          <Col lg={2}>
            <h5 className="mb-3">Quick Links</h5>
            <ul className="list-unstyled">
              <li><Link to="/services" className="text-light">Services</Link></li>
              <li><Link to="/case-studies" className="text-light">Case Studies</Link></li>
              <li><Link to="/about" className="text-light">About Us</Link></li>
              <li><Link to="/contact" className="text-light">Contact</Link></li>
            </ul>
          </Col>
          <Col lg={3}>
            <h5 className="mb-3">Contact Us</h5>
            <p>15, Sakore Nagar, Viman Nagar, Pune</p>
            <p>Phone: (91) 9963771174)</p>
            <p>Email: kalyansomalaraju@gmail.com</p>
          </Col>
          <Col lg={3}>
            <h5 className="mb-3">Stay Updated</h5>
            <p>Subscribe to our newsletter for the latest tech insights.</p>
            <Form onSubmit={handleSubmit} className="mt-3">
              <Form.Group className="mb-3" controlId="newsletter">
                <Form.Control 
                  type="email" 
                  placeholder="Enter your email" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                Subscribe <Send size={18} className="ms-2" />
              </Button>
            </Form>
            {message && (
              <Alert variant={message.type} className="mt-3">
                {message.text}
              </Alert>
            )}
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col className="text-center">
            <p className="mb-0">&copy; 2024 Qfractalia. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;