import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { MapPin, Phone, Mail, Send } from 'lucide-react';
import emailjs from 'emailjs-com';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('sending');

    emailjs.send(
      'YOUR_SERVICE_ID',
      'YOUR_TEMPLATE_ID',
      formData,
      'YOUR_USER_ID'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setStatus('success');
        setFormData({ name: '', email: '', message: '' });
      }, (err) => {
        console.log('FAILED...', err);
        setStatus('error');
      });
  };

  return (
    <section className="contact-section py-5">
      <Helmet>
        <title>Contact Qfractalia - Get in Touch for Innovative Technology Solutions</title>
        <meta name="description" content="Contact Qfractalia for cutting-edge technology solutions. Reach out to our experts for digital transformation, AI, cloud computing, and cybersecurity services." />
        <link rel="canonical" href="https://www.qfractalia.com/contact" />
      </Helmet>
      <Container>
        <h1 className="section-title text-center mb-5">Contact Us</h1>
        <Row>
          <Col lg={6} className="mb-4 mb-lg-0">
            <div className="contact-info-card">
              <h2 className="mb-4">Get in Touch</h2>
              <address>
                <div className="d-flex align-items-center mb-3">
                  <MapPin size={24} className="me-3 text-primary" aria-hidden="true" />
                  <p className="mb-0">15, Sakore Nagar, Viman Nagar, Pune</p>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <Phone size={24} className="me-3 text-primary" aria-hidden="true" />
                  <p className="mb-0"><a href="tel:+919963771174">+91 9963771174</a></p>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <Mail size={24} className="me-3 text-primary" aria-hidden="true" />
                  <p className="mb-0"><a href="mailto:kalyansomalaraju@gmail.com">kalyansomalaraju@gmail.com</a></p>
                </div>
              </address>
              <div className="google-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121058.92836624822!2d73.73762552112198!3d18.52459958893127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf2e67461101%3A0x828d43bf9d9ee343!2sPune%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1629890757761!5m2!1sen!2sin"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Qfractalia office location in Pune"
                ></iframe>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="contact-form-card">
              <h2 className="mb-4">Send Us a Message</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="name">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="email">Email</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="message">Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                  Send Message <Send size={18} className="ms-2" aria-hidden="true" />
                </Button>
              </Form>
              {status === 'sending' && (
                <Alert variant="info" className="mt-3">Sending message...</Alert>
              )}
              {status === 'success' && (
                <Alert variant="success" className="mt-3">Message sent successfully!</Alert>
              )}
              {status === 'error' && (
                <Alert variant="danger" className="mt-3">Failed to send message. Please try again.</Alert>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;