import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { CheckCircle, Target, Users, TrendingUp } from 'lucide-react';
import { Helmet } from 'react-helmet';

const TimelineItem = ({ year, title, description }) => (
  <div className="timeline-item">
    <div className="timeline-badge">{year}</div>
    <div className="timeline-content">
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  </div>
);

const About = () => {
  const values = [
    { icon: <CheckCircle size={32} />, title: 'Integrity', description: 'We uphold the highest standards of integrity in all of our actions.' },
    { icon: <Target size={32} />, title: 'Innovation', description: 'We embrace creativity and constantly pursue new ideas.' },
    { icon: <Users size={32} />, title: 'Collaboration', description: 'We believe in the power of teamwork and partnerships.' },
    { icon: <TrendingUp size={32} />, title: 'Excellence', description: 'We strive for excellence in everything we do.' },
  ];

  const timelineEvents = [
    { year: '2019', title: 'Founded', description: 'Qfractalia was established with a vision to transform businesses through technology.' },
    { year: '2020', title: 'Expansion', description: 'Opened offices in major tech hubs across the country.' },
    { year: '2021', title: 'AI Integration', description: 'Launched our AI division, bringing cutting-edge machine learning solutions to clients.' },
    { year: '2022', title: 'Global Reach', description: 'Expanded operations to Europe and Asia, serving clients worldwide.' },
    { year: '2023', title: 'Industry Leader', description: 'Recognized as a leader in digital transformation by major industry analysts.' },
  ];

  return (
    <div className="about-page">
      <Helmet>
        <title>About Qfractalia - Innovative Technology Solutions Since 2019</title>
        <meta name="description" content="Discover Qfractalia's journey in empowering businesses through innovative technology solutions. Learn about our mission, values, and milestones since 2019." />
        <link rel="canonical" href="https://www.qfractalia.com/about" />
      </Helmet>

      <section className="hero-section text-center py-5">
        <Container>
          <h1 className="display-4 mb-4">About Qfractalia</h1>
          <p className="lead mb-0">Empowering businesses through innovative technology solutions since 2019</p>
        </Container>
      </section>

      <section className="mission-section py-5">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h2 className="mb-4">Our Mission</h2>
              <p className="lead">At Qfractalia, we're on a mission to empower businesses through innovative technology solutions. We believe in harnessing the power of cutting-edge tech to drive growth, efficiency, and competitive advantage for our clients.</p>
            </Col>
            <Col md={6}>
              <img src="https://media.istockphoto.com/id/939787416/photo/two-female-programmers-working-on-new-project-they-working-late-at-night-at-the-office.jpg?s=612x612&w=0&k=20&c=QfNngcOWfyX2ZFWk6XP4wXzlMSF0xDEA34Vyc9fuWms=" alt="Qfractalia team working on innovative solutions" className="img-fluid rounded shadow" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="values-section py-5 bg-light">
        <Container>
          <h2 className="text-center mb-5">Our Core Values</h2>
          <Row>
            {values.map((value, index) => (
              <Col key={index} md={3} className="mb-4">
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body className="text-center">
                    <div className="value-icon mb-3 text-primary">{value.icon}</div>
                    <Card.Title as="h3">{value.title}</Card.Title>
                    <Card.Text>{value.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section className="timeline-section py-5">
        <Container>
          <h2 className="text-center mb-5">Our Journey</h2>
          <div className="timeline">
            {timelineEvents.map((event, index) => (
              <TimelineItem key={index} {...event} />
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
};

export default About;