import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const Services = () => {
  const services = [
    { title: 'Digital Transformation', icon: 'https://cdn-icons-png.flaticon.com/512/1197/1197460.png', description: 'Modernize your business with cutting-edge digital solutions to streamline operations and enhance customer experiences.' },
    { title: 'Cloud Migration & Optimization', icon: 'https://cdn-icons-png.flaticon.com/512/4116/4116449.png', description: 'Seamlessly transition and optimize your infrastructure in the cloud for improved scalability, security, and cost-efficiency.' },
    { title: 'AI & Machine Learning', icon: 'https://cdn-icons-png.flaticon.com/512/2103/2103633.png', description: 'Harness the power of AI and machine learning to drive insights, automate processes, and make data-driven decisions.' },
    { title: 'Custom Software Development', icon: 'https://cdn-icons-png.flaticon.com/512/1688/1688400.png', description: 'Tailored software solutions to address your unique business challenges and drive competitive advantage.' },
    { title: 'Mobile App Development', icon: 'https://cdn-icons-png.flaticon.com/512/2920/2920244.png', description: 'Create powerful, user-friendly mobile applications for iOS and Android to engage customers and improve productivity.' },
    { title: 'Cybersecurity', icon: 'https://cdn-icons-png.flaticon.com/512/2092/2092663.png', description: 'Protect your digital assets with advanced security measures, including threat detection, risk assessment, and compliance management.' },
  ];

  return (
    <section className="py-5">
      <Helmet>
        <title>Qfractalia Services - Innovative Technology Solutions for Business Growth</title>
        <meta name="description" content="Explore Qfractalia's comprehensive range of technology services including digital transformation, cloud migration, AI & machine learning, custom software development, mobile app creation, and cybersecurity solutions." />
        <link rel="canonical" href="https://www.qfractalia.com/services" />
      </Helmet>

      <Container>
        <h1 className="section-title mb-5">Our Technology Services</h1>
        <Row>
          {services.map((service, index) => (
            <Col key={index} lg={4} md={6} className="mb-4">
              <Card className="h-100">
                <Card.Body className="d-flex flex-column">
                  <img src={service.icon} alt={`${service.title} icon`} className="service-icon mb-3 align-self-center" />
                  <Card.Title as="h2">{service.title}</Card.Title>
                  <Card.Text>{service.description}</Card.Text>
                  <Button variant="outline-primary" className="mt-auto align-self-start">Learn More About {service.title}</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Services;