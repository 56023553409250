import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { ArrowRight, Brain, Rocket, Shield, Globe, Database, Users } from 'lucide-react';
import { Helmet } from 'react-helmet';

const Home = () => (
  <>
    <Helmet>
      <title>Qfractalia - Innovative Technology Services for Business Transformation</title>
      <meta name="description" content="Qfractalia  offers cutting-edge technology services including AI, cybersecurity, and cloud computing to drive innovation and growth for businesses worldwide." />
      <link rel="canonical" href="https://www.qfractalia.tech" />
    </Helmet>

    <section className="hero" style={{
      backgroundImage: "url('https://img.freepik.com/premium-photo/digital-transformation-conceptual-generation-technology-era_109643-131.jpg')"
    }}>
      <Container>
        <Row>
          <Col md={8} lg={6}>
            <h1 className="mb-4">Transform Your Business with Qfractalia</h1>
            <p className="lead mb-4">Unlock the power of cutting-edge technology to drive innovation and growth.</p>
            <Button as={Link} to="/services" variant="primary" size="lg">
              Explore Our Solutions <ArrowRight className="ms-2" />
            </Button>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="py-5">
      <Container>
        <h2 className="section-title text-center mb-5">Why Choose Qfractalia?</h2>
        <Row>
          <Col md={4}>
            <Card className="mb-4 text-center hover-card">
              <Card.Body>
                <Globe size={48} className="mb-3 text-primary" />
                <Card.Title as="h3">Global Expertise</Card.Title>
                <Card.Text>Leverage our worldwide network of technology experts and industry insights.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4 text-center hover-card">
              <Card.Body>
                <Users size={48} className="mb-3 text-primary" />
                <Card.Title as="h3">Client-Centric Approach</Card.Title>
                <Card.Text>We tailor our solutions to meet your unique business needs and goals.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4 text-center hover-card">
              <Card.Body>
                <Rocket size={48} className="mb-3 text-primary" />
                <Card.Title as="h3">Proven Results</Card.Title>
                <Card.Text>Our track record of successful projects speaks to our commitment to excellence.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="py-5 bg-light core-capabilities">
      <Container>
        <h2 className="section-title text-center mb-5">Our Core Capabilities</h2>
        <Row className="g-4">
          <Col md={4}>
            <div className="capability-card">
              <Brain size={48} className="capability-icon" />
              <h3>Artificial Intelligence</h3>
              <p>Harness the power of AI to automate processes, gain insights, and make data-driven decisions.</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="capability-card">
              <Shield size={48} className="capability-icon" />
              <h3>Cybersecurity</h3>
              <p>Protect your digital assets with state-of-the-art security solutions and best practices.</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="capability-card">
              <Database size={48} className="capability-icon" />
              <h3>Cloud Computing</h3>
              <p>Leverage cloud technologies to scale your infrastructure, reduce costs, and increase flexibility.</p>
            </div>
          </Col>
        </Row>
        <div className="text-center mt-5">
          <Button as={Link} to="/services" variant="outline-primary" size="lg">
            Explore All Services <ArrowRight className="ms-2" />
          </Button>
        </div>
      </Container>
    </section>
  </>
);

export default Home;