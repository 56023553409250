import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { ArrowRight, ShoppingCart, TrendingUp, Server, Zap, PieChart, Shield } from 'lucide-react';
import { Helmet } from 'react-helmet';

const CaseStudy = ({ title, icon: Icon, description, results }) => (
  <Card className="mb-4 case-study-card">
    <Row className="g-0">
      <Col md={3} className="d-flex align-items-center justify-content-center p-4">
        <Icon size={80} className="case-study-icon" aria-hidden="true" />
      </Col>
      <Col md={9}>
        <Card.Body>
          <Card.Title as="h3">{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
          <h4>Key Results:</h4>
          <ul>
            {results.map((result, index) => (
              <li key={index}>{result}</li>
            ))}
          </ul>
          <Button variant="primary" className="mt-3">
            Read Full Case Study <ArrowRight size={18} className="ms-2" aria-hidden="true" />
          </Button>
        </Card.Body>
      </Col>
    </Row>
  </Card>
);

const CaseStudies = () => {
  const caseStudies = [
    {
      title: 'E-commerce Platform Optimization',
      icon: ShoppingCart,
      description: 'We helped a leading online retailer streamline their e-commerce platform, focusing on user experience and performance optimization.',
      results: [
        'Increased conversion rates by 40%',
        'Improved page load times by 60%',
        'Boosted mobile sales by 35%'
      ]
    },
  
    {
      title: 'E-commerce Platform Optimization',
      icon: ShoppingCart,
      description: 'We helped a leading online retailer streamline their e-commerce platform, focusing on user experience and performance optimization.',
      results: [
        'Increased conversion rates by 40%',
        'Improved page load times by 60%',
        'Boosted mobile sales by 35%'
      ]
    },

    {
      title: 'AI-Driven Supply Chain Management',
      icon: TrendingUp,
      description: 'Implemented an AI-powered supply chain management system for a global manufacturing company to optimize inventory and logistics.',
      results: [
        'Reduced operational costs by 25%',
        'Improved inventory accuracy to 99.9%',
        'Decreased stockouts by 50%'
      ]
    },
    {
      title: 'Cloud Migration for Financial Services',
      icon: Server,
      description: 'Guided a major financial institution through a comprehensive cloud migration, enhancing security and scalability.',
      results: [
        'Achieved 99.99% uptime post-migration',
        'Reduced IT infrastructure costs by 30%',
        'Improved data processing speed by 40%'
      ]
    },
    {
      title: 'IoT Solution for Smart Manufacturing',
      icon: Zap,
      description: 'Developed and implemented an IoT solution for a manufacturing plant, enabling real-time monitoring and predictive maintenance.',
      results: [
        'Reduced equipment downtime by 35%',
        'Increased overall equipment effectiveness by 20%',
        'Achieved energy savings of 15%'
      ]
    },
    {
      title: 'Data Analytics for Retail Optimization',
      icon: PieChart,
      description: 'Implemented advanced data analytics solutions for a retail chain to optimize inventory management and personalize customer experiences.',
      results: [
        'Increased sales by 25% through targeted promotions',
        'Reduced inventory carrying costs by 20%',
        'Improved customer satisfaction scores by 30%'
      ]
    },
    {
      title: 'Cybersecurity Overhaul for Healthcare Provider',
      icon: Shield,
      description: 'Conducted a comprehensive cybersecurity upgrade for a large healthcare provider, ensuring HIPAA compliance and robust data protection.',
      results: [
        'Achieved 100% HIPAA compliance',
        'Reduced security incidents by 75%',
        'Implemented advanced threat detection with 99.9% accuracy'
      ]
    }
  ];

  
  return (
    <section className="py-5">
      <Helmet>
        <title>Qfractalia Case Studies - Proven Success in Technology Solutions</title>
        <meta name="description" content="Explore Qfractalia's success stories in e-commerce optimization, AI-driven supply chain management, cloud migration, IoT solutions, data analytics, and cybersecurity implementations." />
        <link rel="canonical" href="https://www.qfractalia.com/case-studies" />
      </Helmet>
      <Container>
        <h1 className="section-title mb-5">Our Success Stories</h1>
        <p className="text-center mb-5">Discover how we've helped businesses transform and succeed in the digital age.</p>
        {caseStudies.map((study, index) => (
          <CaseStudy key={index} {...study} />
        ))}
      </Container>
    </section>
  );
};

export default CaseStudies;